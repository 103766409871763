<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Page Info">
        <FormRow label="Url Type" required>
          <template #tooltip>
            Select whether you want this page to live within your Commons domain
            (Branch URL) or link "offsite" (External Link).
          </template>
          <RadioGroup :items="urlTypes" v-model="form.url_type" />
        </FormRow>
        <FormRow
          v-if="
            ![
              'none',
              'anchor_on_page',
              'open_submission_form',
              'call_custom_component',
              'parent_menu',
            ].includes(form.url_type)
          "
          tooltip="Here you can give your page a custom URL at your Commons domain."
          label="Custom URL"
          :required="['absolute', 'redirect'].includes(form.url_type)"
        >
          <v-layout
            align-center
            v-if="form.url_type === 'relative' || form.url_type === 'redirect'"
          >
            <span class="mr-3">
              <div v-if="currentUser.active_project_env == 'production'">
                {{ currentUser.production_domain }}/
              </div>
              <div v-else>{{ currentUser.staging_domain }}/</div>
            </span>
            <div>
              <TextField
                placeholder="Custom URL (Root if empty)"
                v-model="form.url"
              />
            </div>
          </v-layout>

          <TextField
            v-else
            placeholder="https://example.com"
            v-model="form.absolute_url"
            :rules="[validation.required, validation.urlOrMailto]"
          />
        </FormRow>
        <FormRow
          v-if="
            ['anchor_on_page', 'call_custom_component'].includes(form.url_type)
          "
          tooltip="Here you can give your page a custom anchor for a section on your single-page Commons domain. (Components in this page will be ignored.)"
          label="Anchor"
          :required="form.url_type == 'anchor_on_page'"
        >
          <TextField placeholder="anchor" v-model="form.url" />
        </FormRow>
        <FormRow
          v-if="['redirect'].includes(form.url_type)"
          tooltip="Here you can give your page a custom anchor for a section on your single-page Commons domain. (Components in this page will be ignored.)"
          label="Redirect URL"
          required
        >
          <TextField placeholder="redirect" v-model="form.absolute_url" />
        </FormRow>
        <FormRow
          v-if="form.url_type == 'parent_menu'"
          label="Parent Menu link to page"
          tooltip="Select a page for this parent menu item to link to."
        >
          <PagesSelector
            :condition="(page) => page.id != form.id"
            v-model="form.parent_menu_link_to_id"
          />
        </FormRow>

        <FormRow
          required
          label="Page Name"
          tooltip="This is the name of your page, as it appears within Commons."
        >
          <TextField
            :rules="[validation.required]"
            placeholder="Page Name"
            v-model="form.title"
          ></TextField>
        </FormRow>

        <div v-if="form.url_type == 'relative'">
          <FormRow
            tooltip="This is the title of your page, as it appears in browsers or tabs."
            label="Page Display Title (in browser/tab)"
          >
            <TextField
              placeholder="Page Display Title (in browser/tab)"
              v-model="form.headline"
            ></TextField>
          </FormRow>

          <FormRow
            label="Page Description"
            tooltip="Add a description to help define this page's purpose."
          >
            <TextArea
              placeholder="Description"
              v-model="form.description"
            ></TextArea>
          </FormRow>

          <FormRow
            label="Social Share Image"
            tooltip="Image that is shown in social shares (16:9 aspect ratio)"
          >
            <Uploader
              v-model="form.social_share_image"
              :ratio="16 / 9"
              enableCropper
            />
          </FormRow>

          <FormRow
            label="Page Meta Tags"
            tooltip="Add any meta data tags you'd like in this field."
          >
            <MetaTagsEditor v-model="form.meta_tags" />
          </FormRow>
        </div>
      </FormPanel>
      <FormPanel label="Page Lede">
        <FormRow label="Use Lede">
          <Checkbox v-model="form.use_lead" />
        </FormRow>
        <div v-if="form.use_lead">
          <FormRow label="Lede Title">
            <TextField
              placeholder="Lede Title"
              v-model="form.lead_title"
            ></TextField>
          </FormRow>
          <FormRow label="Lede Description">
            <TextArea
              placeholder="Lede Description"
              v-model="form.lead_description"
            ></TextArea>
          </FormRow>
          <FormRow label="Lede Text Color">
            <ColorPicker v-model="form.lead_text_color" />
          </FormRow>
          <FormRow>
            <template #label>Lede Background</template>
            <Uploader
              :ratio="400 / 100"
              v-model="form.lead_background"
              enableCropper
            />
          </FormRow>
          <FormRow label="Button Text">
            <TextField
              placeholder="Button Text"
              v-model="form.lead_button_text"
            ></TextField>
          </FormRow>
          <FormRow label="Button Link">
            <TextField
              placeholder="Button Link"
              v-model="form.lead_button_link"
            ></TextField>
          </FormRow>
        </div>
      </FormPanel>
      <FormPanel label="Timeline Details">
        <FormRow label="Timeline Header">
          <TextField
            placeholder="Timeline Header"
            v-model="form.timeline_header"
          ></TextField>
        </FormRow>
        <FormRow label="Timeline Subhead">
          <TextArea
            placeholder="Timeline Subhead"
            v-model="form.timeline_subhead"
          ></TextArea>
        </FormRow>
      </FormPanel>
      <FormPanel label="Page Details">
        <div v-if="form.url_type == 'relative'">
          <FormRow
            label="Venue Links Here"
            tooltip="If links for a venue in event lists should go to this page, select the venue here."
            v-if="
              currentUser.active_project.available_addon_names.includes(
                'Addon::Ticketmaster'
              )
            "
          >
            <TicketmasterVenueSelector
              v-model="form.ticketmaster_venue_id"
              enabled
            />
          </FormRow>
          <FormRow
            label="Page Theme (Default If Empty)"
            tooltip="Choose the theme for this page."
          >
            <ThemesSelector v-model="form.theme_id"></ThemesSelector>
          </FormRow>
          <FormRow
            label="Role-based Access"
            tooltip="Select a role or roles to restirct access to this page for those groups of users."
          >
            <RolesSelector multiple v-model="form.role_ids"></RolesSelector>
          </FormRow>
          <FormRow
            label="Require Authentication"
            tooltip="Select this checkbox if you want to require site authentication to access this page."
          >
            <Checkbox v-model="form.authorized" />
          </FormRow>
        </div>

        <FormRow
          required
          label="Add to Header"
          tooltip="Select if you want this page linked in the header or hidden."
        >
          <RadioGroup :items="addToMenuItems" v-model="form.add_to_menu" />
        </FormRow>

        <div v-if="['menu_bar'].includes(form.add_to_menu)">
          <FormRow label="Add to Sub-Menu" tooltip="Select a parent page.">
            <PagesSelector
              :condition="(page) => page.id != form.id"
              v-model="form.parent_menu_id"
            />
          </FormRow>
        </div>
        <div v-if="['menu_bar', 'grid_menu'].includes(form.add_to_menu)">
          <FormRow
            required
            label="Page Name in Header"
            tooltip="This is the name of your page, as it appears in the header and menu navigation."
          >
            <TextField
              :rules="[validation.required]"
              placeholder="Page Name in Header"
              v-model="form.menu_title"
            />
          </FormRow>
          <FormRow v-if="form.add_to_menu == 'grid_menu'">
            <template #label>Thumbnail Image<br />(320x180)</template>
            <Uploader
              :ratio="320 / 180"
              v-model="form.grid_menu_image"
              enableCropper
            />
          </FormRow>
          <FormRow
            label="Header Position"
            tooltip="This is the order in which pages are listed in the header menu."
          >
            <TextField
              type="number"
              v-model="form.menu_position"
              placeholder="Header Position"
            />
          </FormRow>
          <FormRow
            label="Menu Link Type"
            tooltip="Select whether you want the link to appear as text or a button."
          >
            <RadioGroup :items="menuStyles" v-model="form.menu_style" />
          </FormRow>
          <div v-if="form.menu_style == 'button'">
            <FormRow label="Button Background">
              <ColorPicker v-model="form.menu_button_background" />
            </FormRow>
            <FormRow label="Button Text Color">
              <ColorPicker v-model="form.menu_button_text_color" />
            </FormRow>
          </div>
        </div>

        <FormRow
          label="Add to Footer"
          tooltip="Check the box if you want this page to be linked in the footer as well."
        >
          <Checkbox v-model="form.add_to_footer"></Checkbox>
        </FormRow>

        <div v-if="form.add_to_footer">
          <FormRow required label="Page Title in the Footer">
            <TextField
              :rules="[validation.required]"
              placeholder="Page Title in the Footer"
              v-model="form.footer_menu_title"
            />
          </FormRow>

          <FormRow label="Position in the Footer">
            <TextField
              type="number"
              placeholder="Position in the Footer"
              v-model="form.footer_position"
            ></TextField>
          </FormRow>
        </div>

        <FormRow
          label="Enable Lenis"
          tooltip="Check the box if you want to enable Lenis for this page."
        >
          <Checkbox v-model="form.enable_lenis"></Checkbox>
        </FormRow>
      </FormPanel>
      <FormPanel label="Custom Styles">
        <small>
          <b>{{ form.title || "page" }}.css</b>
        </small>
        <CodeEditor v-model="form.css" />
      </FormPanel>
    </FormPanels>

    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";
export default {
  extends: mixin,
  data() {
    return {
      roles: [],
      addToMenuItems: [
        {
          value: "menu_bar",
          title: "Navigation Menu",
          tooltip:
            "The Menu Bar is located at the top-right of your Page and contains links.",
        },
        {
          value: "grid_menu",
          title: "Grid Menu",
          tooltip:
            "The Grid Menu is a grid of content blocks that link to different Pages. ",
        },
        {
          value: "hidden",
          title: "Hide Page",
          tooltip:
            "This setting allows you to hide a Page. It doesn’t delete your Page, but you can only access it by its unique URL.",
        },
      ],
      urlTypes: [
        { value: "relative", title: "Branch URL" },
        { value: "absolute", title: "External Link" },
        { value: "anchor_on_page", title: "Anchor On Page" },
        { value: "open_submission_form", title: "Open Submission Form" },
        { value: "call_custom_component", title: "Call Custom Component" },
        { value: "parent_menu", title: "Parent Menu" },
        { value: "redirect", title: "Hard Redirect" },
        { value: "none", title: "None" },
      ],
      menuStyles: [
        { value: "link", title: "Text" },
        { value: "button", title: "Button" },
      ],
    };
  },
};
</script>

<style></style>
