<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Entity name" v-if="!linkMode">
        <FormRow
          required
          label="Entity name"
          tooltip="This is the title for your entity."
        >
          <TextField
            :rules="[validation.required]"
            placeholder="Name"
            v-model="form.title"
          ></TextField>
        </FormRow>

        <FormRow
          required
          label="Tech name"
          tooltip="This is the tech name for your entity."
        >
          <TextField
            :rules="[validation.required]"
            placeholder="Tech Name"
            v-model="form.tech_name"
          ></TextField>
        </FormRow>

        <!-- <FormRow
          required
          label="URL"
          tooltip="This is the tech name for your entity."
          v-if="form.schema.schema_type === 'page'"
        >
          <TextField
            :rules="[validation.required]"
            placeholder="URL Path"
            v-model="form.page_attributes.url"
          >
            <template v-slot:prepend>
              <template v-if="form.schema.base_url">
                {{ `/${form.schema.base_url}/` }}
              </template>
              <template v-else> / </template>
            </template>
          </TextField>
        </FormRow> -->

        <div
          v-if="
            form.schema && form.schema.page_ids && form.schema.page_ids.length
          "
        >
          <FormRow
            label="Entity Meta Tags"
            tooltip="Add any meta data tags you'd like in this field."
          >
            <MetaTagsEditor v-model="form.meta_tags" />
          </FormRow>
        </div>
      </FormPanel>
      <template v-if="form.schema && form.schema.schema_type === 'page'">
        <FormPanel label="Page Info">
          <FormRow label="Url Type" required>
            <template #tooltip>
              Select whether you want this page to live within your Commons
              domain (Branch URL) or link "offsite" (External Link).
            </template>
            <RadioGroup
              :items="urlTypes"
              v-model="form.page_attributes.url_type"
            />
          </FormRow>
          <FormRow
            v-if="
              ![
                'none',
                'anchor_on_page',
                'open_submission_form',
                'call_custom_component',
              ].includes(form.page_attributes.url_type)
            "
            tooltip="Here you can give your page a custom URL at your Commons domain."
            label="Custom URL"
            :required="
              ['absolute', 'redirect'].includes(form.page_attributes.url_type)
            "
          >
            <v-layout
              align-center
              v-if="
                form.page_attributes.url_type === 'relative' ||
                form.page_attributes.url_type === 'redirect'
              "
            >
              <span class="mr-3">
                <div v-if="currentUser.active_project_env == 'production'">
                  {{ currentUser.production_domain }}/
                </div>
                <div v-else>{{ currentUser.staging_domain }}/</div>
              </span>
              <div>
                <TextField
                  placeholder="Custom URL (Root if empty)"
                  v-model="form.page_attributes.url"
                />
              </div>
            </v-layout>

            <TextField
              v-else
              placeholder="https://example.com"
              v-model="form.absolute_url"
              :rules="[validation.required, validation.urlOrMailto]"
            />
          </FormRow>
          <FormRow
            v-if="['redirect'].includes(form.page_attributes.url_type)"
            tooltip="Here you can give your page a custom anchor for a section on your single-page Commons domain. (Components in this page will be ignored.)"
            label="Redirect URL"
            required
          >
            <TextField
              placeholder="Redirect"
              v-model="form.page_attributes.absolute_url"
            />
          </FormRow>

          <FormRow
            required
            label="Page Name"
            tooltip="This is the name of your page, as it appears within Commons."
          >
            <TextField
              :rules="[validation.required]"
              placeholder="Page Name"
              v-model="form.page_attributes.title"
            ></TextField>
          </FormRow>

          <div v-if="form.page_attributes.url_type == 'relative'">
            <FormRow
              tooltip="This is the title of your page, as it appears in browsers or tabs."
              label="Page Display Title (in browser/tab)"
            >
              <TextField
                placeholder="Page Display Title (in browser/tab)"
                v-model="form.page_attributes.headline"
              ></TextField>
            </FormRow>

            <FormRow
              label="Page Description"
              tooltip="Add a description to help define this page's purpose."
            >
              <TextArea
                placeholder="Description"
                v-model="form.page_attributes.description"
              ></TextArea>
            </FormRow>

            <FormRow
              label="Social Share Image"
              tooltip="Image that is shown in social shares (16:9 aspect ratio)"
            >
              <Uploader
                v-model="form.page_attributes.social_share_image"
                :ratio="16 / 9"
                enableCropper
              />
            </FormRow>

            <FormRow
              label="Entity Meta Tags"
              tooltip="Add any meta data tags you'd like in this field."
            >
              <MetaTagsEditor v-model="form.page_attributes.meta_tags" />
            </FormRow>
          </div>
        </FormPanel>
        <FormPanel label="Page Details">
          <div v-if="form.page_attributes.url_type == 'relative'">
            <FormRow
              label="Venue Links Here"
              tooltip="If links for a venue in event lists should go to this page, select the venue here."
              v-if="
                currentUser.active_project.available_addon_names.includes(
                  'Addon::Ticketmaster'
                )
              "
            >
              <TicketmasterVenueSelector
                v-model="form.page_attributes.ticketmaster_venue_id"
                enabled
              />
            </FormRow>
            <FormRow
              label="Page Theme (Default If Empty)"
              tooltip="Choose the theme for this page."
            >
              <ThemesSelector
                v-model="form.page_attributes.theme_id"
              ></ThemesSelector>
            </FormRow>
            <FormRow
              label="Role-based Access"
              tooltip="Select a role or roles to restirct access to this page for those groups of users."
            >
              <RolesSelector
                multiple
                v-model="form.page_attributes.role_ids"
              ></RolesSelector>
            </FormRow>
            <FormRow
              label="Require Authentication"
              tooltip="Select this checkbox if you want to require site authentication to access this page."
            >
              <Checkbox v-model="form.page_attributes.authorized" />
            </FormRow>
          </div>

          <FormRow
            required
            label="Add to Header"
            tooltip="Select if you want this page linked in the header or hidden."
          >
            <RadioGroup
              :items="addToMenuItems"
              v-model="form.page_attributes.add_to_menu"
            />
          </FormRow>

          <div v-if="['menu_bar'].includes(form.page_attributes.add_to_menu)">
            <FormRow label="Add to Sub-Menu" tooltip="Select a parent page.">
              <PagesSelector
                :condition="(page) => page.id != form.page_attributes.id"
                v-model="form.page_attributes.parent_menu_id"
              />
            </FormRow>
          </div>
          <div
            v-if="
              ['menu_bar', 'grid_menu'].includes(
                form.page_attributes.add_to_menu
              )
            "
          >
            <FormRow
              required
              label="Page Name in Header"
              tooltip="This is the name of your page, as it appears in the header and menu navigation."
            >
              <TextField
                :rules="[validation.required]"
                placeholder="Page Name in Header"
                v-model="form.page_attributes.menu_title"
              />
            </FormRow>
            <FormRow v-if="form.page_attributes.add_to_menu == 'grid_menu'">
              <template #label>Thumbnail Image<br />(320x180)</template>
              <Uploader
                :ratio="320 / 180"
                v-model="form.page_attributes.grid_menu_image"
                enableCropper
              />
            </FormRow>
            <FormRow
              label="Header Position"
              tooltip="This is the order in which pages are listed in the header menu."
            >
              <TextField
                type="number"
                v-model="form.page_attributes.menu_position"
                placeholder="Header Position"
              />
            </FormRow>
            <FormRow
              label="Menu Link Type"
              tooltip="Select whether you want the link to appear as text or a button."
            >
              <RadioGroup
                :items="menuStyles"
                v-model="form.page_attributes.menu_style"
              />
            </FormRow>
            <div v-if="form.page_attributes.menu_style == 'button'">
              <FormRow label="Button Background">
                <ColorPicker
                  v-model="form.page_attributes.menu_button_background"
                />
              </FormRow>
              <FormRow label="Button Text Color">
                <ColorPicker
                  v-model="form.page_attributes.menu_button_text_color"
                />
              </FormRow>
            </div>
          </div>

          <FormRow
            label="Add to Footer"
            tooltip="Check the box if you want this page to be linked in the footer as well."
          >
            <Checkbox v-model="form.page_attributes.add_to_footer"></Checkbox>
          </FormRow>

          <div v-if="form.page_attributes.add_to_footer">
            <FormRow required label="Page Title in the Footer">
              <TextField
                :rules="[validation.required]"
                placeholder="Page Title in the Footer"
                v-model="form.page_attributes.footer_menu_title"
              />
            </FormRow>

            <FormRow label="Position in the Footer">
              <TextField
                type="number"
                placeholder="Position in the Footer"
                v-model="form.page_attributes.footer_position"
              ></TextField>
            </FormRow>
          </div>

          <FormRow
            label="Enable Lenis"
            tooltip="Check the box if you want to enable Lenis for this page."
          >
            <Checkbox v-model="form.page_attributes.enable_lenis"></Checkbox>
          </FormRow>

          <FormRow
            v-if="form.page_attributes.url_type == 'parent_menu'"
            label="Parent Menu link to page"
            tooltip="Select a page for this parent menu item to link to."
          >
            <PagesSelector
              :condition="(page) => page.id != form.page_attributes.id"
              v-model="form.page_attributes.parent_menu_link_to_id"
            />
          </FormRow>
        </FormPanel>
        <FormPanel label="Custom Styles">
          <small>
            <b>{{ form.page_attributes.title || "page" }}.css</b>
          </small>
          <CodeEditor v-model="form.page_attributes.css" />
        </FormPanel>
      </template>
      <FormPanel label="Fields">
        <SchemaBuilder
          v-if="form.schema_id"
          :schemaId="form.schema_id"
          v-model="form.fields"
          @setName="submitText = $event"
        />
        <template #actions v-if="form.id">
          <v-btn icon @click="copyLink">
            <v-icon size="20">$copy_link</v-icon>
          </v-btn>
        </template>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ form.id ? "Update" : "Add" }} {{ submitText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
import { copyToClipboard } from "@/lib";
export default {
  extends: mixin,
  data() {
    return {
      submitText: this.btnText,
      linkMode: false,
      roles: [],
      addToMenuItems: [
        {
          value: "menu_bar",
          title: "Navigation Menu",
          tooltip:
            "The Menu Bar is located at the top-right of your Page and contains links.",
        },
        {
          value: "grid_menu",
          title: "Grid Menu",
          tooltip:
            "The Grid Menu is a grid of content blocks that link to different Pages. ",
        },
        {
          value: "hidden",
          title: "Hide Page",
          tooltip:
            "This setting allows you to hide a Page. It doesn’t delete your Page, but you can only access it by its unique URL.",
        },
      ],
      urlTypes: [
        { value: "relative", title: "Branch URL" },
        // { value: "absolute", title: "External Link" },
        // { value: "anchor_on_page", title: "Anchor On Page" },
        // { value: "open_submission_form", title: "Open Submission Form" },
        // { value: "call_custom_component", title: "Call Custom Component" },
        // { value: "parent_menu", title: "Parent Menu" },
        { value: "redirect", title: "Hard Redirect" },
        // { value: "none", title: "None" },
      ],
      menuStyles: [
        { value: "link", title: "Text" },
        { value: "button", title: "Button" },
      ],
    };
  },
  created() {
    this.linkMode = "link" in this.$route.query;
  },
  methods: {
    copyLink() {
      copyToClipboard(
        `${this.$route.params.schema_id}_${this.$route.params.id}_`
      );
    },
  },
};
</script>
